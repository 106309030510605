<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/characterList' }">角色列表</el-breadcrumb-item>
                <el-breadcrumb-item>編輯角色</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="auth-name">
                <div class="auth-name-title">
                    <h3>角色名稱</h3>
                </div>
                <el-input class="auth-name-input"
                          placeholder="請輸入名稱"
                          v-model="title"></el-input>
            </div>
            <h3>權限設定</h3>
            <div class="auth-tree">
                <el-tree :data="data"
                         ref="tree"
                         show-checkbox
                         node-key="id"
                         :props="defaultProps"
                         @check="clickDeal"
                         :default-expanded-keys="checkedId"
                         :expand-on-click-node="false"
                         :check-strictly="true">
                </el-tree>
            </div>
            <div class="submit-btn">
                <el-button type="primary"
                           @click="onSubmit()">確認</el-button>
                <el-button @click="resetForm">重填</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { adminAuthUpdatePage, adminAuthUpdate } from '../../../api/index'
export default {
    data() {
        return {
            data: [],
            editId: '',
            title: '', //名稱
            defaultProps: {
                children: 'children',
                label: 'label',
            },
            checkedId: [],
        }
    },
    created() {
        if (typeof this.$route.query.id == 'undefined') {
            this.$router.push({ path: 'accountList' })
        } else {
            this.editId = this.$route.query.id
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            let data = {
                id: this.editId,
            }
            adminAuthUpdatePage(data).then((res) => {
                this.data = this.treeArray(res.data.authorization_select_list)
                this.checkedId = res.data.data_info.authorization
                this.title = res.data.data_info.title
                this.handleSetCheckedKeys()
            })
        },
        handleSetCheckedKeys() {
            this.$refs.tree.setCheckedKeys(this.checkedId)
        },
        clickDeal(currentObj, treeStatus) {
            // 用於：父子節點嚴格互不關聯時，父節點勾選變化時通知子節點同步變化，實現單向關聯。
            let selected = treeStatus.checkedKeys.indexOf(currentObj.id) // -1未選中
            // 選中
            if (selected !== -1) {
                // 子節點只要被選中父節點就被選中
                this.selectedParent(currentObj)
                // 統一處理子節點為相同的勾選狀態
                this.uniteChildSame(currentObj, true)
            } else {
                // 未選中 處理子節點全部未選中
                if (currentObj.children.length !== 0) {
                    this.uniteChildSame(currentObj, false)
                }
            }
        },
        // 統一處理子節點為相同的勾選狀態
        uniteChildSame(treeList, isSelected) {
            this.$refs.tree.setChecked(treeList.id, isSelected)
            for (let i = 0; i < treeList.children.length; i++) {
                this.uniteChildSame(treeList.children[i], isSelected)
            }
        },
        // 統一處理父節點為選中
        selectedParent(currentObj) {
            let currentNode = this.$refs.tree.getNode(currentObj)
            if (currentNode.parent.key !== undefined) {
                this.$refs.tree.setChecked(currentNode.parent, true)
                this.selectedParent(currentNode.parent)
            }
        },
        treeArray(data) {
            let arr = []
            data.forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(element, 'child')) {
                    arr.push({ id: element.id, label: element.title, children: this.treeArray(element.child) })
                } else {
                    arr.push({ id: element.id, label: element.title, children: '' })
                }
            })
            return arr
        },
        onSubmit() {
            this.checkedId = []
            this.$refs.tree.getCheckedNodes().forEach((element) => {
                this.checkedId.push(element.id)
            })
            this.creatAuth()
        },
        creatAuth() {
            let data = {
                id: this.editId,
                title: this.title,
                authorization: this.checkedId,
            }
            adminAuthUpdate(data)
                .then((res) => {
                    if (res.code == 200) {
                        this.$message.success('提交成功！')
                        this.$router.push({ path: 'characterList' })
                    } else if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.$message.error('失敗')
                })
        },
        resetForm() {
            this.checkedId = []
            this.$refs.tree.setCheckedKeys(this.checkedId)
            this.title = ''
        },
    },
}
</script>

<style lang="scss" scoped>
.auth-name {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 30px;
}
.auth-name-title {
    max-width: 120px;
    padding-right: 50px;
    min-width: 90px;
}
.auth-name-input {
    width: 300px;
}
.auth-tree {
    margin: 20px 0;
    padding: 20px 0;
    border: 1px solid #dcdfe6;
}
.submit-btn {
    display: flex;
    justify-content: center;
}
</style>